<template>
  <div id="impressum">
    <h1>Impressum</h1>
    <p>Angaben gemäß § 5 TMG</p>
    <p>
      Gerhard Abendschein
      <br />
      Graf-Siegfried-Straße 28A
      <br />
      54439 Saarburg
      <br />
    </p>
    <p>
      <strong>Vertreten durch: </strong>
      <br />
      Gerhard Abendschein<br />
    </p>
    <p>
      <strong>Kontakt:</strong> <br />
      Telefon: 06851-9988910<br />
      E-Mail: <a href="mailto:info@mathijo.de">info@mathijo.de</a><br />
    </p>
    <p>
      <strong>Haftungsausschluss: </strong><br />
      <br />
      <strong>Haftung für Links</strong><br />
      <br />
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
      Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br /><br /><strong
        >Datenschutz</strong
      ><br /><br />
      Die Nutzung unserer Webseite ist in der Regel ohne Angabe
      personenbezogener Daten möglich. Soweit auf unseren Seiten
      personenbezogene Daten (beispielsweise Name, Anschrift oder
      eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
      freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung
      nicht an Dritte weitergegeben. <br />
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
      Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
      lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
      möglich. <br />
      Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
      Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
      angeforderter Werbung und Informationsmaterialien wird hiermit
      ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
      ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
      Werbeinformationen, etwa durch Spam-Mails, vor.<br />
      <br />
      <br />
      <strong>Google Analytics</strong><br />
      <br />
      Diese Website benutzt Google Analytics, einen Webanalysedienst der Google
      Inc. (''Google''). Google Analytics verwendet sog. ''Cookies'',
      Textdateien, die auf Ihrem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den
      Cookie erzeugten Informationen über Ihre Benutzung dieser Website
      (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den
      USA übertragen und dort gespeichert. Google wird diese Informationen
      benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
      Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um
      weitere mit der Websitenutzung und der Internetnutzung verbundene
      Dienstleistungen zu erbringen. Auch wird Google diese Informationen
      gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben
      oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google
      wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in
      Verbindung bringen. Sie können die Installation der Cookies durch eine
      entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen
      Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
      sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch
      die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über
      Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise
      und zu dem zuvor benannten Zweck einverstanden.
    </p>
    <br />
  </div>
</template>

<script>
export default {
  name: "Impressum",
};
</script>

<style>
#impressum {
  color: white;
}
</style>
